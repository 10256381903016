import React from 'react'
import Step from './Step'

const TimelineSteps = ({ steps, smallText, numbered }) => (
  <div>
    { steps.map((step, i) => (
      <Step
        key={ 'timeline-item-' + i}
        date={ step.date }
        text={ step.text }
        link={ step.link }
        number={ numbered ? i + 1 : '' }
        isLast={ steps.length === (i + 1)}
        smallText={ smallText }
      />
    ))}
  </div>
)

export default TimelineSteps
