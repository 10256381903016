import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screen } from '../../../utils'

// components
import SplitContentContainer from './SplitContentContainer'
import Button from '../Buttons/Button'
import TextButton from '../Buttons/TextButton'
import GradientButton from '../Buttons/GradientButton'
import TimelineSteps from '../TimelineSteps'
import SmartLink from '../SmartLink'

const ImageSide = styled.div`
  background: black;
  padding-bottom: 100%;

  ${tw`
    bg-cover
  `}

  ${screen('lg')} {
    width: 100%;
    padding-bottom: 100%;
  }

  ${(props) =>
    props.image
      ? `
    background-image: url(${props.image.url});
  `
      : ''}
  background-repeat: no-repeat;
`

const sideClasses = `
  py-3
  text-dark-grey-2
`

const SplitContent = ({
  image,
  timeline,
  content,
  button,
  secondaryButton,
  flip = false,
}) => {
  const hasSideContent = image || timeline
  return (
    <SplitContentContainer
      flip={flip}
      content={
        hasSideContent
          ? () => {
              return (
                <>
                  {image ? (
                    image.noCover ? (
                      <img src={image.url} alt={image.alt} />
                    ) : (
                      <ImageSide image={image} />
                    )
                  ) : (
                    timeline && (
                      <div className={sideClasses}>
                        <TimelineSteps smallText steps={timeline} />
                      </div>
                    )
                  )}
                </>
              )
            }
          : null
      }
      contentAside={() => (
        <>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
          {button && button.url && (
            <SmartLink className="no-underline" to={button.url}>
              {button.isGradientButton ? (
                <GradientButton>{button.text}</GradientButton>
              ) : (
                <Button>{button.text}</Button>
              )}
            </SmartLink>
          )}
          {secondaryButton && (
            <SmartLink className="no-underline" to={secondaryButton.url}>
              <TextButton>{secondaryButton.text}</TextButton>
            </SmartLink>
          )}
        </>
      )}
    />
  )
}

export default SplitContent
