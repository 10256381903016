import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import tw from 'tailwind.macro'
import styled from 'styled-components'

// components
import PageWrap from '../components/Layouts/Global/PageWrap'
import SmartLink from '../components/General/SmartLink'

// dynamic components
import WysiwygContent from '../components/General/WysiwygContent'
import ContenImage from '../components/General/ContentImage'
import BlockQuote from '../components/General/Blockquote'
import ScriptEmbed from '../components/General/ScriptEmbed'
import HubspotForm from '../components/General/HubspotForm'
import Mantel from '../components/General/Mantel'
import LeftImageContentBlock from '../components/General/LeftImageContentBlock'
import TabNav from '../components/General/TabNav'
import Button from '../components/General/Buttons/Button'
import ResourceSection from '../components/General/ResourceSection'
import PasswordProtect from '../components/General/PasswordProtect'

// utils
import { rem, sentenceToKebabCase } from '../utils'
import SectionIntro from '../components/General/SectionIntro'
import DownloadAppBtns from '../components/General/DownloadAppBtns'
import TwoColumn from '../components/General/TwoColumn'
import ThreeUp from '../components/Layouts/ThreeUp'
import SplitContent from '../components/General/SplitContent'
import ContentCard from '../components/General/Cards/Content'
import MissionGrid from '../components/General/MissionGrid/MissionGrid'

const SectionContainer = styled.div`
  ${tw`
    px-2
    mx-auto
  `}

  max-width: ${rem(1150)};
`

const ContentWithForm = ({ content, formId }) => {
  return (
    <div className="md:flex">
      <div className="md:w-3/5">
        <WysiwygContent content={content} />
      </div>
      <div
        className="md:ml-4 md:w-2/5 md:sticky self-start h-auto"
        style={{ top: '20px' }}
      >
        <HubspotForm formId={formId} />
      </div>
    </div>
  )
}

const transformSlicesData = function (slice, body) {
  let data = {
    type: slice.slice_type,
  }

  switch (slice.slice_type) {
    case 'wysiwyg_field':
      data.content = slice.primary.content.html
      break
    case 'content_image':
      data.img = {
        src: slice.primary.image.url,
        alt: slice.primary.image.alt,
      }
      // data.caption = slice.primary.caption.text
      data.isVideo = slice.primary.is_a_video === 'yes'
      data.videoUrl = slice.primary.video_url.url
      break
    case 'block_qoute':
      data.text = slice.primary.qoute.text
      break
    case 'script_embed':
      data.embed = slice.primary.script_embed
      break
    case 'section_heading':
      data.title = slice.primary.section_heading.text
      data.centered = true
      data.description = slice.primary.section_description.text
      data.thiccHeading = false
      break
    case 'two_column':
      data.left = slice.primary.left_side.html
      data.right = slice.primary.right_side.html
      data.isNarrow = slice.primary.narrow_columns === 'Yes'
      break
    case 'download_app_btns':
      data.title = slice.primary.title1.text
      data.ios = slice.primary.ios.url
      data.play = slice.primary.play_store.url
      break
    case 'left_image_content_block':
      data.items = slice.items.map((item) => ({
        title: item.left_image_content_block_title.text,
        content: item.left_image_content_block_content.html,
        image: {
          src: item.left_image_content_block_image.url,
          alt: item.left_image_content_block_image.alt,
        },
        link: item.left_image_content_block_link.url,
      }))
      break
    case 'section_jump_nav':
      data.items = body
        .filter((slice) => slice.slice_type === 'section_heading')
        .map((slice) => ({
          active: false,
          anchor: sentenceToKebabCase(slice.primary.section_heading.text),
          text: slice.primary.section_heading.text,
        }))
      break
    case 'content___form':
      data.content = slice.primary.content.html
      data.formId = slice.primary.form_id
      break
    case 'button':
      data.text = slice.primary.button_text.text
      data.url = slice.primary.button_url.url
      break
    case 'password_protect___form':
      data.formId = slice.primary.form_id
      data.password = slice.primary.password
      data.title = slice.primary.lock_screen_title.text
      data.hubspotText = slice.primary.text_above_hubspot_form.text
      data.passwordText = slice.primary.text_above_password_form.text
      data.btnText = slice.primary.button_text.text
      data.errorText = slice.primary.wrong_password_text.text
      break
    case 'resource_section':
      data.title = slice.primary.section_title.text
      data.items = slice.items.map((item) => ({
        type: item.type,
        title: item.resource_title.text,
        description: item.description.text,
        image: {
          alt: item.image.alt,
          src: item.image.url,
        },
        link: item.url.url,
        linkText: item.url_text.text,
      }))
      break
    case 'main_section':
      return {
        ...data,
        intro: {
          title: slice.primary.intro_title.text,
        },
        data: {
          flip: slice.primary.flip_content,
          image: slice.primary.image.url
            ? {
                url: slice.primary.image.url,
                alt: slice.primary.image.alt,
              }
            : null,
          content: slice.primary.content.html,
          button: slice.primary.button_link.url
            ? {
                url: slice.primary.button_link.url,
                text: slice.primary.button_text.text,
              }
            : undefined,
        },
      }
    case 'timeline':
      return {
        ...data,
        intro: {
          title: slice.primary.section_title.text,
        },
        data: {
          flip: true,
          timeline: slice.items.map((item) => {
            return {
              text: item.timeline_points.text,
            }
          }),
          content: slice.primary.content.html,
        },
      }
    case 'stacked_sections':
      return {
        ...data,
        intro: {
          title: slice.primary.intro_title.text,
          description: slice.primary.intro_description.text,
          centered: true,
        },
        data: [
          {
            image: {
              url: slice.primary.top_section_image.url,
              alt: slice.primary.top_section_image.alt,
            },
            content: slice.primary.top_section_content.html,
          },
          {
            flip: true,
            image: {
              url: slice.primary.bottom_section_image.url,
              alt: slice.primary.bottom_section_image.alt,
            },
            content: slice.primary.button_section_content.html,
            button: slice.primary.bottom_section_button_link.url
              ? {
                  url: slice.primary.bottom_section_button_link.url,
                  text: slice.primary.bottom_section_button_text.text,
                }
              : null,
          },
        ],
      }
    case 'three_up':
      return {
        ...data,
        intro: {
          centered: true,
          title: slice.primary.intro_title.text,
          description: slice.primary.description.text,
        },
        data: slice.items.map((item) => {
          return {
            staySmall: true,
            image: {
              src: item.image.url,
              alt: item.image.alt,
            },
            title: item.card_title.text,
            description: item.card_description.text,
          }
        }),
      }
    case 'tabbed_nav':
      return {
        ...data,
        items: slice.items.map((item) => {
          return {
            text: item.tab_title,
            url: item.tab_link.url,
          }
        }),
      }
    case 'our_mission_cards':
      return {
        ...data,
        items: slice.items.map((item) => {
          return {
            text: item.text.text,
          }
        }),
      }
  }

  return data
}

const transformData = function (page) {
  console.log({ page })
  const title = page.data.title.text
  return {
    seo: {
      title,
      description: page.data.meta_description,
    },
    title,
    mantelData: {
      show: page.data.show_mantel === 'Yes',
      thiccHeading: true,
      title: page.data.mantel_title ? page.data.mantel_title.text : null,
      description: page.data.mantel_content
        ? page.data.mantel_content.html
        : null,
      centeredDesc: true,
      reducedPadding: page.uid === 'our-mission',
      extraSmallText: page.uid === 'our-mission',
    },
    content: page.data.body.map((item) =>
      transformSlicesData(item, page.data.body)
    ),
    pageWidth: page.data.page_width,
  }
}

const SectionWrap = ({ children }) => (
  <div className="my-4 md:my-6">{children}</div>
)

const pageWidthMap = {
  Narrow: 'max-w-xl',
  Wide: 'max-w-3xl',
  'Extra Wide': 'max-w-4xl',
}

const Article = ({ data }) => {
  const { prismicPage } = data
  const { seo, title, mantelData, date, content, pageWidth } = transformData(
    prismicPage
  )
  return (
    <PageWrap bg={2} seo={seo}>
      {mantelData.show && <Mantel {...mantelData}></Mantel>}
      <div
        className={`${
          pageWidth && pageWidthMap[pageWidth]
            ? pageWidthMap[pageWidth]
            : 'max-w-xl'
        }
          mx-auto break-words
          ${mantelData.show ? 'mb-4 md:mb-6' : 'my-4 md:my-6'}
        `}
      >
        {!mantelData.show && title ? (
          <div>
            <p className="uppercase font-light text-sm mb-2 font-sans">
              {date}
            </p>
            <h1
              className="
              font-sans
              font-normal
              lg:text-4xl
              text-3xl
              text-charcoal
              leading-tight
            "
            >
              {title}
            </h1>
          </div>
        ) : null}
        {content.map((section, idx) => {
          if (section.type === 'wysiwyg_field') {
            return (
              <SectionWrap key={`section-${idx}`}>
                <WysiwygContent {...section} />
              </SectionWrap>
            )
          }
          if (section.type === 'content_image') {
            return (
              <SectionWrap key={`section-${idx}`}>
                <ContenImage {...section} />
              </SectionWrap>
            )
          }
          if (section.type === 'block_qoute') {
            return (
              <SectionWrap key={`section-${idx}`}>
                <BlockQuote>{section.text}</BlockQuote>
              </SectionWrap>
            )
          }
          if (section.type === 'script_embed') {
            return (
              <SectionWrap key={`section-${idx}`}>
                <ScriptEmbed {...section} />
              </SectionWrap>
            )
          }
          if (section.type === 'section_heading') {
            return (
              <SectionWrap key={`section-${idx}`}>
                <div className="mb-6" id={sentenceToKebabCase(section.title)}>
                  <SectionIntro {...section} />
                </div>
              </SectionWrap>
            )
          }
          if (section.type === 'two_column') {
            return (
              <SectionWrap key={`section-${idx}`}>
                <TwoColumn {...section} />
              </SectionWrap>
            )
          }
          if (section.type === 'download_app_btns') {
            return (
              <SectionWrap key={`section-${idx}`}>
                <DownloadAppBtns {...section} />
              </SectionWrap>
            )
          }
          if (section.type === 'left_image_content_block') {
            return (
              <SectionWrap key={`section-${idx}`}>
                {section.items.map((item, idx) => (
                  <div className="mb-8" key={idx}>
                    <LeftImageContentBlock {...item} />
                  </div>
                ))}
              </SectionWrap>
            )
          }
          if (section.type === 'section_jump_nav') {
            return (
              <SectionWrap key={`section-${idx}`}>
                <TabNav {...section} />
              </SectionWrap>
            )
          }
          if (section.type === 'content___form') {
            return (
              <SectionWrap key={`section-${idx}`}>
                <ContentWithForm {...section} />
              </SectionWrap>
            )
          }
          if (section.type === 'button') {
            return (
              <div key={'section' + idx} className="my-4">
                <SmartLink className="no-underline" to={section.url}>
                  <Button>{section.text}</Button>
                </SmartLink>
              </div>
            )
          }
          if (section.type === 'resource_section') {
            return <ResourceSection key={`section-${idx}`} {...section} />
          }
          if (section.type === 'password_protect___form') {
            return (
              <PasswordProtect
                key={`section-${idx}`}
                {...section}
              ></PasswordProtect>
            )
          }
          if (section.type === 'main_section') {
            return (
              <SectionContainer className={'mb-7'}>
                <div className="mx-auto max-w-lg">
                  <SectionIntro {...section.intro} />
                </div>
                <div className="mt-7">
                  <SplitContent {...section.data} />
                </div>
              </SectionContainer>
            )
          }
          if (section.type === 'stacked_sections') {
            return (
              <SectionContainer className={'mb-10'}>
                <div className="mx-auto max-w-lg">
                  <SectionIntro {...section.intro} />
                </div>
                <div className="mt-7">
                  {section.data.map((content, index) => (
                    <SplitContent key={'contentasfd' + index} {...content} />
                  ))}
                </div>
              </SectionContainer>
            )
          }
          if (section.type === 'timeline') {
            return (
              <SectionContainer className={'mb-10'}>
                <div className="mx-auto max-w-lg">
                  <SectionIntro {...section.intro} />
                </div>
                <div className="mt-7">
                  <SplitContent {...section.data} />
                </div>
              </SectionContainer>
            )
          }
          if (section.type === 'three_up') {
            return (
              <SectionContainer className={'mb-10'}>
                <div className="mx-auto max-w-lg">
                  <SectionIntro {...section.intro} />
                </div>
                <div className="mt-7">
                  <ThreeUp>
                    {data &&
                      data.map((card, index) => (
                        <ContentCard
                          key={'card' + index}
                          {...card}
                          textGrey={true}
                        />
                      ))}
                  </ThreeUp>
                </div>
              </SectionContainer>
            )
          }
          if (section.type === 'tabbed_nav') {
            return (
              <SectionContainer className={'mb-10'}>
                <TabNav {...section} />
              </SectionContainer>
            )
          }

          if (section.type === 'our_mission_cards') {
            return <MissionGrid items={section.items} />
          }
        })}
      </div>
    </PageWrap>
  )
}

export default withPreview(Article)

export const pageQuery = graphql`
  query PageBySlug($uid: String!, $locale: String!) {
    prismicPage(lang: { eq: $locale }, uid: { eq: $uid }) {
      uid
      data {
        meta_description
        title {
          text
        }
        show_mantel
        mantel_title {
          text
        }
        mantel_content {
          html
        }
        page_width
        body {
          ... on PrismicPageBodyWysiwygField {
            slice_type
            primary {
              content {
                html
              }
            }
          }
          ... on PrismicPageBodyScriptEmbed {
            slice_type
            primary {
              script_embed
            }
          }
          ... on PrismicPageBodySectionHeading {
            slice_type
            primary {
              section_heading {
                text
              }
              section_description {
                text
              }
            }
          }
          ... on PrismicPageBodyTwoColumn {
            slice_type
            primary {
              narrow_columns
              left_side {
                html
              }
              right_side {
                html
              }
            }
          }
          ... on PrismicPageBodyContentForm {
            slice_type
            primary {
              content {
                html
              }
              form_id
            }
          }
          ... on PrismicPageBodyContentImage {
            slice_type
            primary {
              image {
                url
                alt
              }
              is_a_video
              video_url {
                url
              }
            }
          }
          ... on PrismicPageBodyDownloadAppBtns {
            slice_type
            primary {
              title1 {
                text
              }
              ios {
                url
              }
              play_store {
                url
              }
            }
          }
          ... on PrismicPageBodyLeftImageContentBlock {
            slice_type
            ...LeftImageContentBlockFields
          }
          ... on PrismicPageBodySectionJumpNav {
            slice_type
          }
          ... on PrismicPageBodyButton {
            slice_type
            primary {
              button_text {
                text
              }
              button_url {
                url
              }
            }
          }
          ... on PrismicPageBodyPasswordProtectForm {
            slice_type
            primary {
              form_id
              password
              lock_screen_title {
                text
              }
              text_above_hubspot_form {
                text
              }
              text_above_password_form {
                text
              }
              wrong_password_text {
                text
              }
              button_text {
                text
              }
            }
          }
          ... on PrismicPageBodyResourceSection {
            slice_type
            primary {
              section_title {
                text
              }
            }
            items {
              description {
                text
              }
              image {
                alt
                url
              }
              resource_title {
                text
              }
              type
              url {
                url
                type
              }
              url_text {
                text
              }
            }
          }

          ... on PrismicPageBodyMainSection {
            slice_type
            primary {
              intro_title {
                text
              }
              image {
                url
                alt
              }
              flip_content
              content {
                html
              }
              button_text {
                text
              }
              button_link {
                link_type
                url
                uid
                document {
                  __typename
                }
              }
            }
          }

          ... on PrismicPageBodyTimeline {
            slice_type
            primary {
              section_title {
                text
              }
              content {
                html
              }
              button_text {
                text
              }
              button_link {
                link_type
                url
                document {
                  ... on PrismicTechnologySubPage {
                    uid
                  }
                }
              }
            }
            items {
              timeline_points {
                html
                text
              }
            }
          }

          ... on PrismicPageBodyStackedSections {
            slice_type
            primary {
              intro_title {
                text
              }
              intro_description {
                text
              }
              top_section_image {
                url
                alt
              }
              top_section_content {
                html
              }
              bottom_section_image {
                url
                alt
              }
              button_section_content {
                html
              }
              bottom_section_button_text {
                text
              }
              bottom_section_button_link {
                link_type
                url
                document {
                  ... on PrismicTechnologySubPage {
                    uid
                  }
                }
              }
            }
          }

          ... on PrismicPageBodyThreeColumn {
            slice_type
            primary {
              intro_title {
                text
              }
              description {
                text
              }
            }
            items {
              image {
                url
                alt
              }
              card_title {
                text
              }
              card_description {
                text
              }
            }
          }

          ... on PrismicPageBodyTabbedNav {
            slice_type
            items {
              tab_title
              tab_link {
                url
                document {
                  ... on PrismicPage {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
          }

          ... on PrismicPageBodyOurMissionCards {
            slice_type
            items {
              text {
                text
              }
            }
          }
        }
      }
    }
  }
`
