import React, { useState, useEffect } from 'react'
import HubspotForm from 'react-hubspot-form'

// add all text to prismic*

const PasswordProtect = ({ formId, password, title, hubspotText, passwordText, btnText, errorText }) => {
  const PORTAL_ID = '5068065'
  const storageName = 'hasLoggedInBefore'

  let hasLoggedInBefore = null

  if (typeof window !== `undefined`) {
    hasLoggedInBefore = window.localStorage.getItem(storageName)
  }

  let [userPass, setUserPass] = useState(null)
  let [showLockScreen, setLockScreen] = useState(true)
  let [showHubspotForm, setShowHubspotForm] = useState(!!formId)
  let [showErrorMessage, setErrorMessage] = useState(false)

  const hideLockScreen = () => {
    setLockScreen(showLockScreen = false)
    setShowHubspotForm(showHubspotForm = false)
  }

  const handleInputChange = (e) => {
    setUserPass(userPass = e.target.value)
    setErrorMessage(showErrorMessage = false)
  }

  const handlePasswordSubmit = (e) => {
    e.preventDefault()
    if (userPass === password) {
      hideLockScreen()
      if (typeof window !== `undefined`) {
        window.localStorage.setItem(storageName, true)
      }
    } else {
      setErrorMessage(showErrorMessage = true)
    }
  }

  useEffect(() => {
    if (hasLoggedInBefore) {
      hideLockScreen()
    }

    if (typeof window !== `undefined`) {
      const handleMessage = event => {
        if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
          setShowHubspotForm(showHubspotForm = false)
        }
      }

      window.addEventListener('message', handleMessage, false)

      return () => {
        window.removeEventListener('message', handleMessage, false)
      }
    }
  }, [])

  return showLockScreen ? (
    <div className="fixed pin z-20 bg-light-grey-1 flex items-center justify-center">
      <div className="bg-white shadow-lg p-2 lg:p-4 max-w-sm">
        <h3 className="text-center font-sans text-lg font-bold mb-1">{title}</h3>
        {showHubspotForm ? (
          <div>
            {hubspotText && (<p className="text-sm text-dark-grey-2 font-sans font-normal leading-tight text-center mb-2">{hubspotText}</p>)}
            <HubspotForm
              portalId={PORTAL_ID}
              formId={formId}
              loading={<div>Loading...</div>}
            />
          </div>
        ) : (
          <div>
            {passwordText && (<p className="text-sm text-dark-grey-2 font-sans font-normal leading-tight text-center mb-2">{passwordText}</p>)}
            <form onSubmit={handlePasswordSubmit}>
              <input
                className="block border border-grey w-full p-1 mb-2 rounded font-sans"
                placeholder="Password"
                type="text"
                defaultValue={userPass}
                onChange={handleInputChange}
              />
              <button
                className="bg-charcoal p-1 mb-2 text-center w-full text-white rounded hover:bg-dark-grey-2"
                type="submit">{btnText}</button>
            </form>
            {showErrorMessage && (
              <p className="text-sm text-center text-orange">{errorText}</p>
            )}
          </div>
        )}
      </div>
    </div>
  ) : null
}

export default PasswordProtect
