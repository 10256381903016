import React from 'react'
import TextButton from '../Buttons/TextButton'
import SmartLink from '../SmartLink'

// need to add icons and label in top right?

const ResourceCard = ({ title, description, image, linkText, link, type }) => (
  <article className="flex flex-col items-start h-full p-4 font-sans shadow-blur border border-light-grey-2 simple-fade-in relative bg-white hover:border-grey hover:shadow-lg">
    <div className="bg-contain bg-left bg-no-repeat mb-2" style={{ backgroundImage: `url('${ image.src }')`, width: '60%', height: '80px', backgroundPosition: 'center left' }}></div>
    <h4 className="mb-2 text-2xl">{ title }</h4>
    <p className="mb-6 leading-normal">{ description }</p>
    <SmartLink to={link} className="mt-auto no-underline">
      <TextButton>{linkText}</TextButton>
    </SmartLink>
    <a href={link} target="_blank" className="block absolute pin"></a>
  </article>
)

export default ResourceCard
