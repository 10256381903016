import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

const dateClasses = `
  pr-3
  uppercase
  font-sans
  text-dark-grey-2
  text-sm
  tracking-wide
  flex-no-shrink
  w-32
`

const textWrapClasses = `
  border-l-2
  border-green-1
  relative
`

const dotClasses = `
  absolute
  pin-l
  bg-green-1
  rounded-full
`

const textClasses = `
  text-dark-grey-2
  font-sans
  font-bold
  leading-tight
  text-black
  no-underline
`

function Text ({ text, number, link = {}, smallText }) {
  const TextTag = link.url
    ? (link.external ? 'a' : Link)
    : 'div'

  return (
    <TextTag
      className={ classNames(textClasses, {
        'text-base': smallText,
        'text-xl lg:text-2xl': !smallText,
        'hover:underline': link.url
      }) }
      to={ !link.external && link.url ? link.url : null }
      href={ link.url || null }
      target={ link.external ? '_blank' : null }
    >
      { number ? `${ number }. ${ text }` : text }
    </TextTag>
  )
}

const Step = ({ text, link, date, number, smallText, isLast }) => {
  return (
    <div className="flex">
      { date &&
      <div className={ dateClasses }>{ date }</div>
      }
      <div className={ classNames(textWrapClasses, {
        'pl-3 pb-4': smallText,
        'pl-4 pb-6': !smallText
      }) } style={{ paddingBottom: isLast ? '0px' : '' }}>
        <div className={ dotClasses } style={{ width: '8px', height: '8px', marginLeft: '-5px' }} />
        <Text text={ text } link={ link } smallText={ smallText } number={ number }/>
      </div>
    </div>
  )
}

export default Step
