import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screen } from '../../../utils'

import WysiwygContent from '../WysiwygContent'

const Container = styled.div`
  ${ tw`
    lg:flex
  ` }

  ${ props => props.isNarrow ? tw`
    lg:-mx-4
    text-center
  ` : tw`
    lg:-mx-1
  ` }

  ${ props => props.isNarrow ? `
    img {
      width: 209px;
    }
  ` : `
    img {
      width: 100%;
    }
  ` }

  ${ screen('xl') } {
    width: 120%;
    position: relative;
    left: -10%;
  }
`

const Column = styled.div`
  ${ tw`
    lg:w-1/2
  ` }

  ${ props => props.isNarrow ? tw`
    lg:mx-4
  ` : tw`
    lg:mx-1
  ` }
`

const TwoColumn = ({ left, right, isNarrow }) => {
  return (
    <Container isNarrow={ isNarrow }>
      <Column isNarrow={ isNarrow }>
        <WysiwygContent content={ left } smallText={ true }></WysiwygContent>
      </Column>
      <Column isNarrow={ isNarrow }>
        <WysiwygContent content={ right } smallText={ true }></WysiwygContent>
      </Column>
    </Container>
  )
}

export default TwoColumn
