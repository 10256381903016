import React from 'react'
import classNames from 'classnames'

// components
import Button from '../../General/Buttons/Button'
import GradientButton from '../../General/Buttons/GradientButton'
import VideoPlay from '../../Shapes/VideoPlay'
import SmartLink from '../../General/SmartLink'

const LinkWrapperForButton = ({ children, button }) => {
  if (!button) {
    return (
      <>
        { children }
      </>
    )
  }

  return (
    <SmartLink className="no-underline" to={ button.url }>
      { children }
    </SmartLink>
  )
}

const ContentCard = ({ image, date, title, description, button, staySmall = false, isVideo, smallText = false, textGrey = false }) => {
  const containerClasses = `
    max-w-md
    mx-auto
    mb-5
  `

  const dateClasses = `
    ${ textGrey ? 'text-dark-grey-2' : 'text-black' }
    uppercase
    text-xs
    font-normal
    mb-1
    font-sans
  `

  const headingClasses = `
    font-bold
    ${ textGrey ? 'text-dark-grey-2' : 'text-black' }
    ${ smallText ? 'text-base' : 'text-lg' }
    leading-normal
    mb-2
    font-sans
  `

  const descriptionClasses = `
    ${ textGrey ? 'text-dark-grey-2' : 'text-black' }
    font-sans
    text-base
    font-light
    leading-normal
    mb-4
    lg:mb-6
  `

  const playIconWrapClasses = `
    text-white
    v-center
    absolute
    w-full
    text-center
  `

  const playIconBgClasses = `
    absolute
    pin-l
    pin-t
    w-full
    h-full
    bg-black-trans
  `

  return (
    <div className={ containerClasses }>
      { image &&
        <div className="mb-2">
          <LinkWrapperForButton button={ button }>
            <div className="relative">
              <img className="w-full align-bottom" src={ image.src } alt={ image.alt } />
              { isVideo &&
                <div className={ playIconBgClasses }>
                  <div className={ playIconWrapClasses }>
                    <VideoPlay />
                  </div>
                </div>
              }
            </div>
          </LinkWrapperForButton>
        </div>
      }
      { date &&
        <p className={ dateClasses }>{ date }</p>
      }
      <LinkWrapperForButton button={ button }>
        <h4 className={ classNames(headingClasses, { 'lg:text-2xl': !staySmall }) }>
          { title }
        </h4>
      </LinkWrapperForButton>
      { description &&
        <div className={ descriptionClasses } dangerouslySetInnerHTML={{ __html: description }} />
      }
      { button &&
        <LinkWrapperForButton button={ button }>
          { button.isGradientButton
            ? <GradientButton>{ button.text }</GradientButton>
            : <Button>{ button.text }</Button>
          }
        </LinkWrapperForButton>
      }
    </div>
  )
}

export default ContentCard
