import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { rem } from '../../../utils'

const Container = styled.div`
  ${tw`
    mx-auto
  `}

  max-width: ${rem(1080)};

  ${(props) =>
    props.extraWide
      ? `
    @media screen and (min-width: 1400px) {
      max-width: ${rem(1300)};
    }
  `
      : ``}
`

const SectionContainer = ({ children, extraWide = false, ...rest }) => (
  <Container extraWide={extraWide} {...rest}>
    {children}
  </Container>
)

export default SectionContainer
