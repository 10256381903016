import React from 'react'
import styled from 'styled-components'
import { screen } from '../../../utils/index'

// components
import SectionContainer from '../SectionContainer'

const Icon1 = () => (
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34.8512 44.1029H19.1491V42.7677C19.1491 42.2762 19.1217 41.7826 19.0669 41.2932H34.9337C34.8788 41.7826 34.8514 42.2762 34.8514 42.7677L34.8512 44.1029ZM30.9574 52.5782H23.0431C20.8936 52.5761 19.1513 50.8337 19.1492 48.6843V45.36H34.8514V48.6843C34.8492 50.8338 33.1068 52.5761 30.9574 52.5782ZM39.066 33.4547C37.0748 35.1633 35.6973 37.473 35.1384 40.0362H18.8622C18.3982 37.8804 17.3435 35.8976 15.8184 34.3073V24.7099L24.3067 19.8098C24.5007 19.6959 24.621 19.4892 24.621 19.2635V12.6339C26.4519 12.3091 27.7302 10.6385 27.5677 8.78432C27.4032 6.93229 25.8507 5.51268 23.9923 5.51268C22.1319 5.51268 20.5794 6.93229 20.417 8.78432C20.2524 10.6385 21.5307 12.3091 23.3616 12.6339V18.9006L14.8734 23.8007H14.8755C14.6814 23.9125 14.5612 24.1213 14.5612 24.3449V33.1178C9.90789 28.8104 7.81982 22.3977 9.04292 16.1753C10.2663 9.95445 14.6265 4.80987 20.5644 2.58237C26.5022 0.356976 33.168 1.36526 38.1819 5.24862V10.0622L29.6936 14.9623C29.4995 15.0741 29.3793 15.2808 29.3793 15.5065V22.1383C27.5484 22.4631 26.2701 24.1337 26.4325 25.9879C26.5971 27.8399 28.1475 29.2616 30.0079 29.2616C31.8684 29.2616 33.4209 27.8399 33.5833 25.9879C33.7478 24.1337 32.4696 22.4631 30.6365 22.1383V15.8716L39.1248 10.9715C39.3189 10.8597 39.4391 10.6509 39.4391 10.4273V6.31609C43.2338 9.83452 45.3602 14.7957 45.2884 19.97C45.2167 25.1443 42.9554 30.0442 39.066 33.4554L39.066 33.4547ZM23.9924 11.4329C24.9353 11.4329 25.7833 10.8634 26.144 9.9943C26.5047 9.12312 26.3064 8.12115 25.6399 7.45463C24.9733 6.78805 23.9714 6.58766 23.1002 6.94838C22.229 7.30908 21.6616 8.15917 21.6616 9.10206C21.6637 10.3888 22.7057 11.4308 23.9924 11.4329ZM30.0124 23.3405C29.0695 23.3405 28.2194 23.9079 27.8587 24.7791C27.498 25.6481 27.6984 26.6522 28.3649 27.3187C29.0315 27.9853 30.0334 28.1836 30.9046 27.8229C31.7758 27.4622 32.3432 26.6121 32.3432 25.6692C32.3432 25.0512 32.0985 24.4584 31.6598 24.0218C31.2232 23.5851 30.6304 23.3405 30.0124 23.3405Z"
      fill="#59AF39"
    />
  </svg>
)

const Icon2 = () => (
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      d="m61.215 39.342-6.93 2.771v8.475l6.93-2.772v-8.474ZM54.286 31.389l-.415.165a.64.64 0 0 1-.449 0l-.415-.165v4.62l-5.814 2.323 6.474 2.588 6.474-2.588-5.814-2.323v-4.62h-.041ZM11.715 42.109l-6.93-2.772v8.475l6.93 2.771v-8.474ZM13.036 31.389l-.415.165a.639.639 0 0 1-.449 0l-.415-.165v4.62L5.902 38.36l6.474 2.56 6.474-2.588-5.814-2.295V31.39ZM33.66 11.174v8.474l6.203-2.48 1.155.962 3.697 3.075 1.32 1.104v4.883l6.93 2.771V21.49L49.5 20.104l-3.465-1.387v1.875l-1.32-1.101-2.805-2.339-1.32-1.1v-7.65l-6.93 2.772ZM26.527 7.425l6.474 2.589 6.474-2.589L33 4.837l-6.474 2.588ZM13.035 21.489v8.474l6.93-2.771v-4.883l1.32-1.104 3.697-3.075 1.155-.962 6.203 2.48v-8.474l-6.93-2.772v7.65l-1.32 1.1-2.805 2.339-1.32 1.1v-1.873l-6.93 2.77ZM11.715 27.983v-6.495l-6.93-2.771v8.474l6.93 2.772v-1.98Z"
      fill="#59AF39"
    />
    <path
      d="m18.85 17.74-6.474-2.586-6.474 2.586 6.474 2.589 6.474-2.589ZM61.215 18.717l-3.465 1.387-3.465 1.384v8.475l6.93-2.772v-8.474ZM47.15 17.74l6.474 2.589 6.474-2.589-6.474-2.586-6.474 2.586ZM33.728 33.727a2.99 2.99 0 0 1-.964.66c.005.111.026.222.06.33v.093-.003c.025.08.058.155.097.225l.052.085.002.003c.057.097.124.188.204.27l.381.377h.003c.47-.222.897-.521 1.266-.885a4.406 4.406 0 0 0 1.036-1.645 4.436 4.436 0 0 0 0-2.91 9.624 9.624 0 0 0-.152-.397v.002a4.332 4.332 0 0 0-.91-1.32l-3.782-3.895a4.386 4.386 0 0 0-3.135-1.32 4.44 4.44 0 0 0-4.182 2.733 4.44 4.44 0 0 0 1.014 4.89l3.833 3.842h.003c.002-.052.002-.1 0-.152V33.635c0-.093 0-.166.033-.25.034-.086 0-.191.052-.284.051-.093 0-.157.051-.237v-.073l-2.897-2.89V29.9a2.85 2.85 0 0 1 0-4.027 2.849 2.849 0 0 1 4.027 0l3.908 3.828a2.84 2.84 0 0 1 0 4.02v.007Z"
      fill="#59AF39"
    />
    <path
      d="M42.584 38.149c0-1.181-.478-2.31-1.32-3.135l-3.834-3.842a5.982 5.982 0 0 1-.186 2.073l2.898 2.89a2.849 2.849 0 0 1 0 4.027 2.85 2.85 0 0 1-4.027 0L32.273 36.3a2.841 2.841 0 0 1 0-4.02 2.91 2.91 0 0 1 .965-.66 1.531 1.531 0 0 0-.441-1.002l-.382-.376h-.002a4.428 4.428 0 0 0-1.266 7.154l3.867 3.862a4.397 4.397 0 0 0 3.135 1.32 4.443 4.443 0 0 0 4.434-4.437v.008ZM47.092 48.24l5.873 2.348v-8.474l-6.93-2.772v8.06l-1.286 1.07-2.839 2.369v1.722l4.027-3.36 1.155-.964ZM20.058 49.196l4.032 3.367V50.84l-2.843-2.37-1.282-1.07v-8.064l-6.93 2.771v8.475l5.868-2.344 1.155.957ZM40.59 49.652l-6.93 2.771v8.475l6.93-2.772v-8.474ZM32.34 52.424l-6.93-2.772v8.475l6.93 2.771v-8.474ZM39.475 48.675 33 46.087l-6.474 2.588 6.474 2.589 6.474-2.589Z"
      fill="#59AF39"
    />
  </svg>
)

const Icon3 = () => (
  <svg
    width="48"
    height="56"
    viewBox="0 0 48 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <g clip-path="url(#clip0_817_272)">
      <path
        d="M24 0L48.2487 14V42L24 56L-0.248711 42V14L24 0Z"
        fill="#59AF39"
      />
      <path
        d="M27.7117 16.002C27.5847 16.0213 27.4732 16.133 27.45 16.1614C27.4383 16.1757 27.4131 16.1899 27.4063 16.2071L15.0872 30.3227C15.031 30.3816 15 30.4609 15 30.5513C15 30.731 15.1328 30.8712 15.3044 30.8712H21.573L18.9618 41.544V41.612C18.9579 41.6324 18.9404 41.6588 18.9404 41.6801C18.9404 41.8598 19.0732 42 19.2448 42C19.3408 42 19.427 41.9573 19.4842 41.8863L31.9128 27.0396C31.969 26.9807 32 26.9014 32 26.8111C32 26.6313 31.8672 26.4922 31.6956 26.4922H24.9697L27.9735 16.4815C27.9938 16.4378 27.9948 16.3962 27.9948 16.3444C27.9948 16.1647 27.9861 16.0854 27.8426 16.0255C27.8 16.0062 27.7543 15.9949 27.7117 16.002Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_817_272">
        <rect width="48" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const MissionOl = styled.ol`
  list-style: none;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-row-gap: 26px;
  grid-column-gap: 26px;
  padding: 0 26px;
  max-width: 1024px;
  margin: 0 auto;

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-top: 6px solid #5aaf39;
    padding: 54px 35px 35px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  li span {
    color: #5aaf39;
    font-size: 92px;
    font-weight: 900;
  }

  li svg {
    margin-top: auto;
  }

  ${screen('lg')} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const MissionGrid = ({ items }) => {
  return (
    <SectionContainer className="mt-4 mb-10">
      <MissionOl>
        {items.map((item, i) => {
          const { text } = item
          return (
            <li className="bg-white text-center font-sans" key={i}>
              <span>{i + 1}.</span>
              {text && (
                <p
                  className="font-black text-black text-lg leading-tight mb-3"
                  dangerouslySetInnerHTML={{ __html: text }}
                ></p>
              )}
              {i === 0 ? (
                <Icon1 />
              ) : i === 1 ? (
                <Icon2 />
              ) : i === 2 ? (
                <Icon3 />
              ) : (
                ''
              )}
            </li>
          )
        })}
      </MissionOl>
    </SectionContainer>
  )
}

export default MissionGrid
