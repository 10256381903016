import React from 'react'
import { graphql } from 'gatsby'
import WysiwygContent from '../WysiwygContent'
import SmartLink from '../SmartLink'

export const query = graphql`
  fragment LeftImageContentBlockFields on PrismicPageBodyLeftImageContentBlock {
    items {
      left_image_content_block_title {
        text
      }
      left_image_content_block_content {
        html
      }
      left_image_content_block_image {
        alt
        url
      }
      left_image_content_block_link {
        url
      }
    }
  }
`

const LeftImageContentBlock = ({
  title,
  content,
  image,
  link
}) => {
  return (
    <article className="md:flex items-start">
      <img className="flex-none mb-3 md:mb-0" src={image.src} alt={image.alt} />
      <div className="self-stretch pl-3 md:ml-3 border-l-4 border-green-2">
        <h2 className="
          mb-1
          text-2xl
          font-sans
          leading-tight
          text-bold
          "
        >
          <SmartLink
            to={link}
            className="
              text-black
              visited:text-teal
              no-underline
              hover:underline
            "
          >
            { title }
          </SmartLink>
        </h2>
        <WysiwygContent
          content={content}
        />
      </div>
    </article>
  )
}

export default LeftImageContentBlock
