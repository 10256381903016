import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import { screen, rem } from '../../../utils/'

import iosImg from '../../../images/ios_download_btn.png'
import playImg from '../../../images/play_download_btn.png'

const Btn = styled.a`
  display: block;
  width: 200px;

  ${ tw`
    m-1
  ` }
`

const DownloadAppBtns = ({ title, ios, play }) => (
  <div className=''>
    <h4 className='text-center mb-4'>{ title }</h4>
    <div className='flex justify-center flex-wrap'>
      <Btn href={ ios } target='_blank'><img src={ iosImg } alt='ios app store download button' /></Btn>
      <Btn href={ play } target='_blank'><img src={ playImg } alt='play store app download button' /></Btn>
    </div>
  </div>
)

export default DownloadAppBtns
