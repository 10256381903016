import React from 'react'
import ResourceCard from './ResourceCard'
import ThreeUp from '../../Layouts/ThreeUp'

const ResourceSection = ({ title, items }) => {
  return (
    <div className="mb-10">
      <h1 className="mb-4">{ title }</h1>
      <ThreeUp>
        {items.map(item => <ResourceCard key={ item.title } { ...item } />)}
      </ThreeUp>
    </div>
  )
}

export default ResourceSection
